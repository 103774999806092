<template>
  <div class="container bg-white p-20">
    <div class="cert_title mb-20">
      账号信息
    </div>

    <!--    <div class="cert_card_two">-->
    <!--      <div class="div1">企业名称：<span>{{ datas.businessName }}</span></div>-->
    <!--      <div class="div2">联系电话：<span>{{ datas.phone }}</span></div>-->
    <!--      <div class="div3">统一社会信用代码/组织机构代码：<span>{{ datas.organizationCode }}</span></div>-->
    <!--      <div class="div4">法人代表：<span>{{ datas.legalPerson }}</span></div>-->
    <!--      <div class="div5">详细地址：<span>{{ datas.companyAddress }}</span></div>-->
    <!--    </div>-->
    <!--    <div class="cert_card_three">-->
    <!--      <div>营业执照：</div>-->
    <!--      &lt;!&ndash; <div>图片大小</div> &ndash;&gt;-->
    <!--      &lt;!&ndash; <UploadImg></UploadImg> &ndash;&gt;-->
    <!--      <div>-->
    <!--        <img v-if="datas.credentialsPic" :src="datas.credentialsPic">-->
    <!--        &lt;!&ndash; <div v-else>上传图片</div> &ndash;&gt;-->
    <!--        &lt;!&ndash; <img v-if="data.value" :src="data.value" class="img-solt">-->
    <!--                    <img v-else class="img-solt" src="@/assets/images/businessLicence.png" alt=""> &ndash;&gt;-->

    <!--      </div>-->
    <!--    </div>-->
    <a-descriptions title="" bordered >
      <a-descriptions-item label="认证信息" :span="3">
        <div class="cert_card_one">
          <div class="img">
            <img :src="datas.imgUrl">
            <div class="text">
              <div class="text1">{{ datas.businessName }}</div>
              <div class="text2">
                <div>
                  <img
                    src="https://store.heytapimage.com/cdo-portal/feedback/202211/07/9956f306b4d75cb678b17c63a31a17fc.png">
                  已认证
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-descriptions-item>

      <a-descriptions-item label="企业名称" :span="2">
        {{ datas.businessName }}
      </a-descriptions-item>

      <a-descriptions-item label="联系电话">
        {{ datas.phone }}
      </a-descriptions-item>
      <a-descriptions-item label="组织机构代码" :span="2">
        {{ datas.organizationCode }}
      </a-descriptions-item>
      <a-descriptions-item label="法人代表">
        {{ datas.legalPerson }}
      </a-descriptions-item>
      <a-descriptions-item label="详细地址" :span="3">
        {{ datas.companyAddress }}
      </a-descriptions-item>

      <a-descriptions-item label="营业执照" :span="3">
        <img v-if="datas.credentialsPic" :src="datas.credentialsPic" style="max-width: 30%;">
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import UploadImg from '@/components/UpLoadImg/UploadImg'
import {getUserInfoRz} from '@/api/Login'

export default {
    name: 'Certification',
    components: { UploadImg },
    props: {
        userD: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    computed: {
        ...mapGetters(['userInfo'])
    },
    data() {
        return {
          datas: {}
        }
    },
    mounted() {
      if (Object.keys(this.userInfo).length === 0 || this.infoProfect === 0 || this.infoProfect === 2) return
      getUserInfoRz().then(res => {
        this.datas = res.data
        console.log(this.datas, 'this.获取标签列表数据')
      })
  },
    create() { }
}
</script>

<style lang="scss" scoped>
.container {
    position: relative;

    .cert_title {
        // position: absolute;
        // top: 25px;
        // left: 25px;
        font-size: 18px;
        font-weight: 700;
        color: #000;
    }

    .cert_card_one {
        // position: absolute;
        // left: 25px;
        // top: 80px;
        width: 700px;

        .img {
            display: flex;
            align-items: center;


            img {
                width: 60px;
                height: 60px;
                object-fit: cover;
            }

            .text {
                margin-left: 20px;
                width: 220px;

                .text1 {
                    margin-top: 5px;
                    color: #202124;
                    font-size: 14px;
                    font-weight: 700;
                }

                .text2 {
                    margin-top: 5px;
                    display: flex;
                    justify-content: space-between;

                    >div:nth-child(1) {
                        img {
                            width: 15px;
                            height: 15px;
                            margin-right: 5px;
                        }

                        display: flex;
                        color: #E7402E;
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .cert_card_two {
        // position: absolute;
        margin: 40px 0 0 60px;

        display: grid;
        grid-template-columns: repeat(2, 350px) 1fr;
        grid-template-rows: repeat(2, 50px);
        grid-column-gap: 10px;
        grid-row-gap: 0px;

        span {
            color: #202124;
            display: inline-block;
            margin-left: 15px;
            font-size: 16px;
        }

        >div {
            font-size: 16px;
            font-weight: 400;
        }

        .div1 {
            grid-area: 1 / 1 / 2 / 2;
        }

        .div2 {
            grid-area: 1 / 2 / 2 / 3;
        }

        .div3 {
            grid-area: 1 / 3 / 2 / 4;
        }

        .div4 {
            grid-area: 2 / 1 / 3 / 2;
        }

        .div5 {
            grid-area: 2 / 2 / 3 / 3;
        }
    }

    .cert_card_three {
        margin: 10px 0 0 60px;
        display: flex;
        align-items: flex-start;
        font-size: 16px;
        font-weight: 400px;

        >div:nth-child(2) {
            cursor: pointer;
            margin-left: 15px;
            width: 80px;
            height: 80px;
            line-height: 80px;
            // border: 1px dashed#87a1c4;
            text-align: center;
            font-size: .8rem;
            color: #93c5ee;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

}
</style>
